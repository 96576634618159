// @file Common types that we use
import type { UserRole } from '@@/bits/user_model'
import type {
  AccessibilitySettingPreference,
  ApiErrorCode,
  CommandLevel,
  ContentModerationCategory,
  ContentModerationMode,
  DelinquentCardStatus,
  DiscussionStyle,
  Gradable,
  GradeCategory,
  LibraryMembershipRole,
  LibraryType,
  LmsSyncState,
  ModerationCategories,
  NotificationChannelType,
  NotificationType,
  SchoolPermissionString,
  SchoolStudentPermissionBoolean,
  SchoolTeacherPermissionBoolean,
  ShownCursorType,
  UserAccountType,
  WallAccessPrivacyType,
  WallAccessRight,
  WallAccessRole,
  WallCollaboratorStatus,
  WallGradeCalculationMethods,
  WallPermission,
  WallPrivacy,
  WallPrivacyOption,
  WallVizName,
} from '@@/enums'
import type { Card, Invoice, JsonAPIResource, Link, PlanDisplay, Reaction, WallBackgroundType } from '@padlet/arvo'
import type { CSSProperties } from 'vue'

type Id = number
type HashId = string
type UserId = Id
type UserGroupId = Id
type TenantId = Id
type WallId = Id
type FolderId = Id
type LibraryId = Id
type LibraryMembershipId = Id
type PrivacyPolicyId = Id
type OnboardingFlowId = Id

interface DeviceDetails {
  // Gadgets
  ipad: boolean
  ipod: boolean
  iphone: boolean
  iphoneX: boolean

  // OSes
  ios: boolean
  android: boolean
  windowsPhone: boolean
  mac: boolean
  windows: boolean
  webos: boolean
  blackberry: boolean
  windowsTouch: boolean

  // Browsers
  edge: boolean
  chrome: boolean
  firefox: boolean
  ie: boolean
  webkit: boolean
  safari: boolean
  ie11: boolean
  ie10: boolean
  version: number

  // Platform type
  mobile: boolean
  desktop: boolean
  supported: boolean

  // Capabilities
  touchable: boolean
  mousable: boolean
  retina: boolean
  clickGesture: string
  dragFile: boolean
  cookies: boolean

  // Variants
  tablet: boolean
  phone: boolean
  iosApp: boolean
  androidApp: boolean
  app: boolean
  desktopApp: boolean
  nwjs: boolean
  electronApp: boolean
  zoomApp: boolean

  // Push
  safariPush: boolean
  webPush: boolean
  browserPush: boolean
}

interface UserQuota {
  walls_used: number
  walls_limit: number
  advertized_walls_limit: number
  can_make: boolean
}

interface User {
  id: UserId
  hashid?: HashId
  tenant_id: TenantId
  paying: boolean
  quota: UserQuota
  role: UserRole
  wall_role?: 'owner' | 'admin' | 'teacher' | 'student' | null
  lang: string
  created_at: Date
  registered_at: Date | null
  defaultAvatar?: string
  avatar?: string
  avatar_dominant_rgb: number[]
  paying_for_self_only?: boolean
  membership_tier?: string
  period?: string | null
  name?: string
  username?: string
  display_name?: string
  email?: string
  short_name?: string
  registered?: boolean
  profile_url?: string
  bio?: string
  status?: string
  isBetaFeaturePreviewEnabled?: boolean
  canUpdateEmail?: boolean
  canUpdateName?: boolean
  canUpdateUsername?: boolean
  tenant_type: string
  hasRecentPadlets?: boolean
  defaultLibraryId?: string
  visible?: boolean
  // Specifically used to trigger snackbar when redirected to dashboard settings after changing and verifying email
  isEmailRecentlyUpdatedAndVerified?: boolean
  // Specifically used to trigger snackbar when redirected to dashboard after login and verifying email
  isEmailRecentlyLoggedInAndVerified?: boolean
  // true if user's email address is verified, or if the user logs in via SSO
  is_verified?: boolean
  account_type?: UserAccountType
  days_before_freeze: number
  personal_pricing_plan_variant?: string
  is_archive_enabled: boolean
  hasCreatedPadletBefore?: boolean

  // Accessibility preferences
  is_keyboard_shortcuts_enabled?: boolean
  is_app_messages_auto_dismissed?: boolean
  high_contrast_mode_preference?: AccessibilitySettingPreference
  reduced_motion_preference?: AccessibilitySettingPreference
}

interface UserCamelCase {
  id: UserId
  tenant_id: TenantId
  paying: boolean
  quota: UserQuota
  role: UserRole
  lang: string
  createdAt: Date
  registeredAt: Date | null
  defaultAvatar?: string
  avatar?: string
  payingForSelfOnly?: boolean
  membershipTier?: string
  period?: string | null
  name?: string
  username?: string
  displayName?: string
  email?: string
  shortName?: string
  registered?: boolean
  profileUrl?: string
  bio?: string
  isBetaFeaturePreviewEnabled?: boolean
  canShowEmail?: boolean
  canUpdateEmail?: boolean
  canUpdateName?: boolean
  canUpdateUsername?: boolean
  tenantType: string
  hasRecentPadlets?: boolean
  defaultLibraryId?: string
  visible?: boolean
  // Specifically used to trigger snackbar when redirected to dashboard settings after changing and verifying email
  isEmailRecentlyUpdatedAndVerified?: boolean
  // Specifically used to trigger snackbar when redirected to dashboard after login and verifying email
  isEmailRecentlyLoggedInAndVerified?: boolean
  // true if user's email address is verified, or if the user logs in via SSO
  isVerified?: boolean
  accountType?: UserAccountType
  isNative?: boolean
  days_before_freeze: number

  // Accessibility preferences
  isKeyboardShortcutsEnabled?: boolean
  isAppMessagesAutoDismissed?: boolean
  highContrastModePreference?: AccessibilitySettingPreference
  reducedMotionPreference?: AccessibilitySettingPreference
}

interface UserWithHashId extends User {
  hashid: string
}

interface UserOptions {
  canAddLibrary?: boolean
  canManagePeople?: boolean
  canMakeInLibrary?: boolean
  canMakeInTenant?: boolean
  canViewGallery?: boolean
  linkedGoogleDriveEmail?: string
  canSearchWallsOnDashboard?: boolean
  isTeacher?: boolean
  showClassInformation?: boolean
}

type AccountType = 'user' | 'library'
type AccountKey = { type: 'library'; id: LibraryId } | { type: 'user'; id: UserId }
type SharedCollectionAccountKey = AccountKey

interface TenantAssets {
  home_logo?: string
}

interface TenantToLibraryMigrationState {
  completionWeight: number
  libraryId: number
  tenantId: number
}

interface FreezeBannerState {
  days_until_freeze: number
  role: string
  free_trial: boolean
  payment_link?: string
}

interface Tenant {
  id: TenantId
  assets: Partial<TenantAssets> | null
  domain_name: string
  membership: string
  name: string
  type?: string
  alternate_user_domains?: string
  auto_create_accounts?: boolean
  enable_google_app_licensing?: boolean | null
  enable_external_rostering?: boolean | null
  enable_oneroster?: boolean | null
  google_app_licensing_order_id?: string | null
  google_app_licensing_redeem_uri?: string | null
  invalid_email_domains?: string[]
  owner_email_domain?: string | null
  enable_google_login?: boolean | null
  enable_office365_login?: boolean | null
  enable_classlink_login?: boolean | null
  enable_clever_login?: boolean | null
  enable_saml_login?: boolean
  classlink_tenant_id?: string | null
  clever_district_id?: string | null
  is_lti_ready?: boolean
  is_native?: boolean
  ownerLink: string
  hide_password_login_if_sso_enabled: boolean
  dashboard_settings_visibility_toggles: {
    password: boolean
  }
  lti_enabled?: boolean
  enable_lti_user_group_sync?: boolean
  settings?: {
    permissions?: {
      // student wall permissions
      studentDefaultWallPrivacy?: WallPrivacy
      studentDefaultWallPermission?: WallPermission
      canStudentsMakeWalls?: boolean
      canStudentsMakePrivateWalls?: boolean
      canStudentsMakeOrgWideHiddenWalls?: boolean
      canStudentsMakeOrgWideDashboardWalls?: boolean
      canStudentsMakePasswordWalls?: boolean
      canStudentsMakeSecretWalls?: boolean
      // teacher wall permissions
      teacherDefaultWallPrivacy?: WallPrivacy
      teacherDefaultWallPermission?: WallPermission
      canTeachersMakeWalls?: boolean
      canTeachersMakePrivateWalls?: boolean
      canTeachersMakeOrgWideHiddenWalls?: boolean
      canTeachersMakeOrgWideDashboardWalls?: boolean
      canTeachersMakePasswordWalls?: boolean
      canTeachersMakeSecretWalls?: boolean
      // student permission
      canStudentsViewGallery?: boolean
      // teacher permissions
      canTeachersAddNewUsers?: boolean
      canTeachersEditUsers?: boolean
      // briefcase permissions
      briefcaseDefaultWallPrivacy?: WallPrivacy
      briefcaseDefaultWallPermission?: WallPermission
    }
  }
}

type TenantParamsKeys =
  | 'assets'
  | 'domain_name'
  | 'name'
  | 'alternate_user_domains'
  | 'auto_create_accounts'
  | 'enable_google_login'
  | 'enable_office365_login'
  | 'enable_classlink_login'
  | 'enable_google_app_licensing'
  | 'enable_external_rostering'
  | 'enable_oneroster'
  | 'google_app_licensing_order_id'
  | 'google_app_licensing_redeem_uri'
  | 'enable_clever_login'
  | 'hide_password_login_if_sso_enabled'
  | 'classlink_tenant_id'
  | 'clever_district_id'

type TenantParams = Pick<Tenant, TenantParamsKeys>

interface TenantInviteLinks {
  id?: TenantId
  studentLink?: string
  teacherLink?: string
  adminLink?: string
  isStudentLinkCodeEnabled?: boolean
  isTeacherLinkCodeEnabled?: boolean
  isAdminLinkCodeEnabled?: boolean
}

interface TenantInviteLinksParams {
  generateStudentLinkCode: boolean
  generateTeacherLinkCode: boolean
  generateAdminLinkCode: boolean
  isStudentLinkCodeEnabled: boolean
  isTeacherLinkCodeEnabled: boolean
  isAdminLinkCodeEnabled: boolean
}

interface TenantAnalytics {
  orgAnalyticsEmbedLink: string
  monthlyAnalyticsEmbedLink: string | null
  orgUserAnalyticsEmbedLink: string
}

interface LibraryAnalytics {
  libraryAnalyticsEmbedLink: string
  monthlyAnalyticsEmbedLink: string | null
  libraryUserAnalyticsEmbedLink: string
}

interface LibraryAttributeCheck {
  valid: boolean
  slugError?: string
  nameError?: string
}

interface DomainValidation {
  result: boolean
  subdomain?: string
  code?: ApiErrorCode
}

type WallViz =
  | 'free'
  | 'grid'
  | 'matrix'
  | 'shelf'
  | 'stream'
  | 'map'
  | 'timeline'
  | 'table'
  | 'timeline_v2'
  | 'whiteboard'

type WallVizNameType = keyof typeof WallVizName

interface OnboardingWallViz {
  name: WallVizNameType
  value: WallViz
  imagePath: string
  sectionsImagePath: string
}

interface WallBackground {
  url: string
  thumbnail_url: string
  palette_order: number
  width: number
  height: number
  fill: 'cover' | 'tile' | 'auto' | 'undefined'
  effects: string[]
  is_full_page: boolean
  luminance_name: 'light' | 'dark'
  dominant_color: number[]
  dominant_color_as_rgb: string
  primary_color: number[] | null
  primary_color_as_rgb: string | null
  primary_text_color: 'white' | 'black' | null
  accent_color: number[] | null
  accent_color_as_rgb: string | null
  accent_text_color: 'white' | 'black' | null
  is_custom?: boolean
  name?: string
  // for backward compatibility
  effect?: string | null
  is_title_bar_transparent_by_default?: boolean
  is_title_bar_transparent?: boolean
  type: WallBackgroundType
}

interface Wall {
  id: WallId
  hashid: HashId
  library_id?: LibraryId | null
  name?: string
  description?: string
  namespace?: string
  address: string
  archived_at: string | null
  created_at: string
  updated_at: string
  last_present_at?: string | null
  days_till_deletion?: number | null
  title: string
  viz: WallViz
  collaborator_right: number
  portrait?: string
  post_size?: PostSize
  builder: {
    id: UserId
    avatar: string
    display_name: string
    username: string
    name?: string
  }
  background: WallBackground
  thumbnail: {
    dominant_color: number[]
    dominant_color_as_rgb: string
    height: number | null
    width: number | null
    url: string
  }
  links: {
    show: string
    permanent: string
    peek: string
  }
  is_private?: boolean
  frozen?: boolean
  font_id?: number
  color_scheme?: string
  audio_length_limit?: number
  video_length_limit?: number
  whiteboard_frame_limit?: number
}

export type VideoLengthLimit = null | number // number of seconds
interface WallPostProperties {
  subject_placeholder: string | null
  body_placeholder: string | null
  subject_enabled: boolean
  attachment_settings: boolean | Record<string, any>
  attachment_text: string | null
  body_enabled: boolean
  color_enabled: boolean
  location_enabled: boolean
  ar_lenses_enabled: boolean
  required_fields: {
    subject: boolean
    body: boolean
    attachment: boolean | Record<string, any>
  }
  video_length_limit: VideoLengthLimit
}

// keep these in sync with the backend in services/rails/app/services/wall_post_properties/custom_post_date_property.rb
type DateDisplayFormat = 'local' | 'friendly' | 'mmddyy' | 'ddmmyy' | 'iso' | 'unix'
type NumberDisplayFormat = 'local' | 'comma_period' | 'period_comma' | 'space_comma' | 'space_period' | 'custom'
type NumberDecimalPlaces = number | 'as_entered'
type CustomPostPropertyDataType = 'text' | 'date' | 'number' | 'link_button' | 'single_select'

type WallCustomPostProperty =
  | WallCustomPostDateProperty
  | WallCustomPostTextProperty
  | WallCustomPostNumberProperty
  | WallCustomPostLinkButtonProperty
  | WallCustomPostSingleSelectProperty
interface WallCustomPostPropertyBase {
  id?: string // id of a custom property is a random string not a number
  name: string
  data_type: CustomPostPropertyDataType
  sort_index: number
  required: boolean
}

export interface WallCustomPostTextProperty extends WallCustomPostPropertyBase {
  data_type: 'text'
}

export interface WallCustomPostDateProperty extends WallCustomPostPropertyBase {
  data_type: 'date'
  date_display_format: DateDisplayFormat
  show_time: boolean
}

export interface WallCustomPostNumberProperty extends WallCustomPostPropertyBase {
  data_type: 'number'
  number_display_format: NumberDisplayFormat
  decimal_places: NumberDecimalPlaces
  thousand_separators: boolean | null
}

export interface WallCustomPostLinkButtonProperty extends WallCustomPostPropertyBase {
  data_type: 'link_button'
}

export interface WallCustomPostSingleSelectProperty extends WallCustomPostPropertyBase {
  data_type: 'single_select'
  selection_options: SingleSelectOption[]
}

export interface SingleSelectOption {
  sort_index: number
  default: boolean
  name: string
  id?: string
}

interface WallTemplate {
  id: WallId
  name?: string
  description?: string
  title: string
  thumbnail: {
    height: number
    width: number
    url: string
    dominantColor: number[]
    dominantColorAsRgb: string
  }
}

type FilterGroup = 'grade' | 'category'

type TemplateFilters = {
  [group in FilterGroup]: Record<string, boolean>
}

interface WallGalleryTemplate {
  id: WallId
  name?: string
  description?: string
  title: string
  thumbnail: {
    height: number
    width: number
    url: string
    dominantColor: number[]
    dominantColorAsRgb: string
  }
  galleryTemplateId: string
  category?: string[]
  audience: string
  grades?: string[]
  previewPadletUrl: string
  relatedTemplateIds: string[]
  // TODO: change backend to send data in camel case
  exampleGalleryImages: Array<{
    image_height: number
    image_id: number
    image_url: string
    image_width: number
  }>
  templateCreator?: string
}

interface WallSuggestedTemplatesData {
  totalNumberOfGalleryTemplateWallsCreated: number
  recentGalleryTemplates: string[]
  suggestedTemplateIds: string[]
}

interface WallCamelCase {
  id: WallId
  description?: string
  libraryId: LibraryId | null
  name: string
  namespace: string
  address: string
  archivedAt: string | null
  createdAt: string
  updatedAt: string
  lastPresentAt: string | null
  daysTillDeletion?: number | null
  title: string
  viz: WallViz
  collaboratorRight: number
  isInRecents: boolean
  builder: {
    id: UserId
    avatar: string
    displayName: string
    username: string
  }
  background: {
    dominantColor: number[]
    dominantColorAsRgb: string
    fill: string
    height?: number
    width?: number
    url: string
    effect: string | null
    luminanceName: 'light' | 'dark'
  }
  thumbnail: {
    dominantColor: number[]
    dominantColorAsRgb: string
    height: number | null
    width: number | null
    url: string
  }
  links: {
    show: string
    permanent: string
  }
  isTemplate?: boolean
  frozen?: boolean
}

export enum PostSize {
  STANDARD = 'standard',
  WIDE = 'wide',
}

type ReactionType = 'like' | 'vote' | 'star' | 'grade' | 'none'
interface WallReactionData {
  type: ReactionType
  max_value?: number
}
interface Folder {
  id: FolderId
  name: string
  // TODO ENG-4489 remove snake case field after removing gql client
  walls_count?: number
  wallsCount?: number
  icon?: string
}

interface UserGroup {
  id: UserGroupId
  name: string
}

interface Theme {
  id: number
  name: string
  full_name: string
  color_scheme: 'light' | 'dark'
  font_id: number
}

interface PinDropSuggestion {
  locationName: string
  placeId: string
  types?
  zoom: number
}

type Cid = string
type PostColor = 'red' | 'orange' | 'green' | 'blue' | 'purple' | undefined | null
interface PostPositionMap {
  top?: number
  left?: number
}

interface Point {
  longitude: number
  latitude: number
}

export interface PollChoice {
  choice_text: string
  poll_choice_id: Id
  total_votes: number
  user_voted: boolean
}

export interface PollResult extends PollChoice {
  percentage: number
  pollWinner: boolean
}

export interface Poll {
  type: 'poll'
  poll_id: Id
  user_voted?: boolean
  question: string
  poll_choices: PollChoice[]
  closed_at?: string | null
  edit_poll?: boolean
  voter_id: Id
}

// Attachment props can have various formats.
// We can be flexible for now until we have
// a better idea of how it should looks like.
type AttachmentProps = Record<string, any>

interface WishContent {
  attachment_props: AttachmentProps
  is_processed?: boolean
}

interface PostAttributes {
  cid?: Cid
  id?: Id
  hashid?: HashId
  subject?: string
  body?: string
  attachment?: string
  attachment_link?: Link | null
  attachment_caption?: string | null
  file?: File | null
  published?: boolean
  width?: number
  wallSectionId?: number
  wall_section_hashid?: string
  sortIndex?: number
  wall_section_id?: number
  created_at?: Date | string
  updated_at?: Date | string
  content_updated_at?: Date | string
  author_id?: number
  author_hashid?: HashId
  author?: User
  wall_id?: number
  wall_hashid?: HashId
  permalink?: string
  sort_index?: number
  rank?: string
  position?: PostPositionMap
  top?: number
  left?: number
  color?: PostColor
  row_id?: number
  col_id?: number
  rowCid?: string
  colCid?: string
  row_span?: number
  col_span?: number
  locationPoint?: Point
  location_point?: Point
  locationName?: string
  location_name?: string
  is_content_hidden?: boolean
  wish_content?: WishContent
  scheduled_at?: string | null
  published_at?: string
  moderation_violations?: ModerationCategories[]
  pinned_at?: string | null
  custom_properties?: PostCustomProperties | null
}

type PostCustomPropertyValue = string | number | undefined // TODO: this type will be expanded over time
type PostCustomProperties = Record<string, PostCustomPropertyValue>

interface UpdatePostArgs extends PostAttributes {
  gap_size?: number | null
}

interface MapPostAttributes extends PostAttributes {
  location_point: Point
  location_name: string
}

interface FileAttributes {
  name: string
  size: number | null
  isGoogleDriveFile: boolean
}

interface UpdateDraftAttributes extends PostAttributes {
  draftCreatedAt?: number
  minimizedPlaceholderTitle?: string
  uploadingFile?: File | FileAttributes | null
  uploadProgress?: number
  maxFileUploadSize?: number
  shouldAutoPublish?: boolean
}

interface Post extends PostAttributes {
  cid: Cid
}

interface DraftPost extends Post {
  // timestamp generated using Date.now()
  draftCreatedAt?: number
  minimizedPlaceholderTitle?: string
  uploadingFile?: File | null
  uploadProgress?: number
  maxFileUploadSize?: number
  shouldAutoPublish?: boolean
  hasCustomPropertyErrors?: boolean
  shouldStartEditing?: boolean
}

type CommentTempId = string
type CommentId = Id | CommentTempId
interface Comment {
  id: CommentId
  hashid: HashId
  wish_id: Id
  user_id?: Id
  user_hashid?: HashId
  body: string
  html_body?: string
  attachment?: string
  attachment_signed_url?: string
  user: User
  created_at: string
  updated_at: string
  moderation_violations?: ModerationCategories[]
  is_awaiting_approval?: boolean
  published_at?: string
  submitted_at?: string
}

type CommentAttachmentSettings = Record<string, boolean>

interface Section {
  id: Id
  hashid: HashId
  title: string
  color?: string
  sort_index: number
  created_at?: string
  updated_at?: string
}

type LabelledSection = Section & {
  ariaLabelledby?: string
}

interface DeleteSectionOptions {
  showSnackbar?: boolean
}

interface PostConnection {
  id: Id
  label: string
  from_wish_id: Id
  to_wish_id: Id
}

export type ModalSize = 'default' | 'wide' | 'extra-wide'

// Personal  plans
interface Plan {
  id: string
  name: string
  period: 'monthly' | 'annual'
  price: string
  formattedPrice: string
  currency: string
  tier: 'platinum' | 'gold'
  is_legacy: boolean
  url: string
  annual_savings_percentage: number
}

interface AccumulatedReactionData {
  sum: number
  totalReactionsCount: number
  reactionType: ReactionType
  userReaction?: Reaction
}
type AccumulatedReactions = Record<Id, AccumulatedReactionData | undefined>

// Estimated cancellation refund
interface EstimateCancellationRefundResult {
  amount: string
  currency: string
}

// Library plans
type LibraryPlan = Plan

// Library invoices
type LibraryInvoice = Invoice

// Backpack plans
interface BackpackPlanPrices {
  '10'?: number
  '15'?: number
  '25'?: number
  '35'?: number
  '50'?: number
  '75'?: number
  '100'?: number
  '125'?: number
}

interface BackpackPlanResult {
  prices: BackpackPlanPrices
  currency: string
}

interface GoogleDriveAuthorizationResult {
  linkedEmail: string | null
  accessToken: string | null
}

// Estimated downgrade to Team Neon refund
interface EstimateTeamNeonDowngradeRefund {
  amount: string
  currency: string
}

// Library Change card URL
interface ChangeCardUrl {
  change_card_url: string
}

// Downgrade to Team Neon result
interface LibraryDowngradeToTeamNeonResult {
  status_link: string
}

// Downgrade to Team Neon result
interface LibrarySwitchResult {
  status_link: string
}

interface LibrarySwitchStatusResult {
  switch_status: {
    is_switched: boolean
    is_refunded?: boolean
    error_message?: string
  }
}

interface BillingFree {
  tier: 'neon'
}

interface BillingPaid {
  plan: PlanDisplay
  period: 'year' | 'month'
  card: Card
  tier: 'silver' | 'gold' | 'platinum'
  payment_channel: 'android' | 'ios' | 'chargebee' | null
  status: {
    is_paused: boolean
    is_pausing_soon: boolean
    is_plan_pausable: boolean
    is_cancelling_soon: boolean
    is_in_trial: boolean
    is_refundable: boolean
    is_purchased_via_reseller: boolean
  }
  switch_plan_status: {
    is_switching_soon: boolean
    new_plan_id: string
  }
  pause_date: string | null
  resume_date: string | null
  term_ends_date: string | null
  trial_end_date: string | null
  is_sponsored?: boolean
}

type Billing = BillingFree | BillingPaid

// Result from /billing/pause, /billing/resume, /billing/cancel_pausing
// which is the result (i.e. fields in billing to update) for the operations
// @see @padlet/arvo for type definition for Billing
interface BillingPauseResult {
  is_plan_pausable: boolean
  is_paused: boolean
  is_pausing_soon: boolean
  pause_date: string
  resume_date: string
  term_ends_date: string
}

// Result from /premium/undo_scheduled_plan_switch or /premium/undo_scheduled_cancellation
interface UndoScheduledChangeResult {
  success?: boolean
  errors?: string[]
}

type UndoLibraryScheduledChangeResult = UndoScheduledChangeResult

interface EstimateSwitchingCostResult {
  is_refund: boolean
  currency_code: string
  new_plan_cost: string
  unused_portion: string
  refund_amount: string
  amount_due: string
  invoice_amount: string
}

type EstimateLibrarySwitchingCostResult = EstimateSwitchingCostResult

interface ScheduledChangesResult {
  data: {
    has_scheduled_changes: boolean
  }
}

interface Quota {
  max_upload_megabytes: number | null
  max_walls: number | null
  max_whiteboard_frames: number | null
  max_video_length_seconds: number | null
}

interface AdvertisedQuotasResult {
  neon: Quota
  silver: Quota
  gold: Quota
  platinum: Quota
}

interface AdvertisedQuotasApiResult {
  data: {
    neon: Quota
    silver: Quota
    gold: Quota
    platinum: Quota
  }
}

interface SwitchPlanResult {
  links: {
    status: string
  }
}

interface SwitchCurrencyResult {
  refund_successful: boolean
  switch_successful: boolean
}

type DockableModalState = 'expanded' | 'docked' | 'minimized'

// `type` should be name of action relative to root
interface DispatchableAction {
  type: string
  payload?: unknown
}

interface UploadedFile extends File {
  isGoogleDriveFile?: boolean
}

interface PopoverAnchor {
  position?: 'top' | 'side' | 'bottom'
  top: number
  left: number
  width: number
  height?: number
}

type ActiveInputTag = 'section' | 'location' | 'postColor' | 'none'

interface LocationMenuPlaceInfo {
  id: string
  name: string
  longitude?: number
  latitude?: number
}

// TODO: Remove this information from Libraries when we have licenses service and API
interface LibraryUserQuotaInfo {
  isCappedPlan?: boolean
  advertizedStudentQuota: number
  advertizedTeacherQuota: number
  advertizedTeacherAndAdminQuota: number
  maxStudentQuota: number
  maxTeacherQuota: number
  maxTeacherAndAdminQuota: number
  studentQuotaHit: boolean
  studentQuotaUsed: number
  teacherQuotaHit: boolean
  teacherQuotaUsed: number
  adminQuotaUsed: number
  ownerQuotaUsed: number
  maxOwnerQuota: number
}

interface Library {
  id: LibraryId
  ownerId: number
  ownerType: string
  name: string
  slug: string
  dashboardUrl: string
  avatar?: string
  defaultAvatar?: string
  bio?: string
  libraryType?: LibraryType
  privacy?: string
  version?: number
  createdAt?: string
  updatedAt?: string
  joinedAt?: string
  libraryAccess?: LibraryAccess
  rosteringEnabled?: boolean
  addedMembershipsCount?: number
  nonLeftMembershipsCount?: number
  addedOrSuspendedMembershipsCount?: number
  makersCount?: number
  membershipTier?: string
  membershipDuration?: 'monthly' | 'annual'
  quota: {
    wallsUsed: number
    wallsLimit: number
    quotaHit: boolean
    advertizedMaxUploadMegabytes: number
    advertizedWallsLimit: number
    maxUploadMegabytes: number
  }
  trialDaysLeft?: number
  isTrialBehaviorEnabled?: boolean
  isDowngradedFromPayingTier?: boolean
  isOwnerTeacherOrStaff?: boolean
  isPlanCancelled: boolean
  isTrialExpired?: boolean
  isDeprecatedFreeTier: boolean
  userQuota?: LibraryUserQuotaInfo
  visible?: boolean
  host?: string
  isArchiveEnabled: boolean
  membership?: any[]
  ltiEnabled?: boolean
  ltiUserGroupSyncEnabled?: boolean
}

interface LibraryBillingFree {
  tier: 'neon' | 'trial'
}

interface LibraryBillingPaid {
  plan: PlanDisplay
  users_billed_count: number
  card: Card
  tier: 'gold'
  period: 'year' | 'month'
  payment_channel: 'chargebee'
  status: {
    is_cancelling_soon: boolean
    is_refundable: boolean
  }
  switch_plan_status: {
    is_switching_soon: boolean
    new_plan_id: string | null
  }
  term_ends_date: string
}

type LibraryBilling = LibraryBillingFree | LibraryBillingPaid

interface UserLibrarySetting {
  libraryId: string
  avatar: string
  name: string
  namespace: string
  libraryType: LibraryType
  visible: boolean
  disabled: boolean
}

type SchoolLibraryStudentPermission = Record<SchoolStudentPermissionBoolean, boolean> &
  Record<SchoolPermissionString, string>

type SchoolLibraryTeacherPermission = Record<SchoolTeacherPermissionBoolean, boolean> &
  Record<SchoolPermissionString, string>

interface SchoolLibraryPermission {
  settings: {
    student: SchoolLibraryStudentPermission
    teacher: SchoolLibraryTeacherPermission
  }
}

interface CanSetWallPrivacyOption {
  name: WallPrivacyOption
  canSet: boolean
}

interface CanSetWallPrivacyOptions {
  privacyOptions: CanSetWallPrivacyOption[]
}

type OnboardingTask =
  | 'addNewTeam'
  | 'addMembers'
  | 'customizeTeamInfo'
  | 'makePrivateWall'
  | 'pickPlan'
  | 'connectSchoolSso'
  | 'addAdmins'
  | 'addTeachersAndStudents'
  | 'manageUserPermissions'

interface OnboardingData {
  completedAt: string | null
  id: OnboardingFlowId
  libraryId: LibraryId
  tasks: Record<OnboardingTask, boolean | string>
}

interface OnboardingTaskData {
  key: OnboardingTask
  icon: string
  taskStringOne: string
  taskStringTwo: string
  taskButtonText: string
  taskButtonLabel: string
  isTaskDone: boolean
  taskDoneAt: string | null
  order: number
}

interface LibraryMembership {
  id: LibraryMembershipId
  libraryId: LibraryId
  userId: UserId
  role: LibraryMembershipRole
  status: 'added' | 'deactivated' | 'suspended' | 'left'
  user: Partial<User>
  updatedAt: string
  createdAt: string
}

interface LibraryUserEmailDomain {
  domainName: string

  // TODO: PRO-4890 update role to school role
  // should be Student or Teacher
  role: 'admin' | 'maker' | 'contributor'
}

export enum LibraryOauthProvider {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  APPLE = 'apple',
  CLEVER = 'clever',
  CLASSLINK = 'classlink',
  PASSWORD = 'password',
}

interface LibraryOauthMethod {
  name: LibraryOauthProvider
  enabled: boolean
}

interface LibraryOauthSettings {
  oauth: LibraryOauthMethod[]
  cleverDistrictId?: string | null
  classlinkTenantId?: string | null
}

interface LibraryAccess {
  canViewLibraryInfo: boolean
  canUpdateLibraryInfo: boolean
  canSetupLti: boolean
  canUpdatePermissions: boolean
  canViewLibraryMemberships: boolean
  canViewLibraryAnalytics: boolean
  canManageMembers: boolean
  canManageBilling: boolean
  canTrashLibrary: boolean
  canUseLibraryAsOwner: boolean
  canUseLibraryAsAdmin: boolean
  canUseLibraryAsTeacher: boolean
  canViewLibrarySecurity: boolean
  canInviteSchoolAdmins: boolean
  canInviteSchoolTeachers: boolean
  canInviteSchoolStudents: boolean
  canInviteMembers: boolean
  canViewGallery: boolean
  canManageContentSafetySettings: boolean
  canTransferWalls: boolean
}

interface LibraryInviteLinks {
  id: Id
  linkForAdmin?: string
  linkForMaker?: string
  linkForContributor?: string
  linkForTeacher?: string
  linkForStudent?: string
  codeForAdminEnabled: boolean
  codeForMakerEnabled?: boolean
  codeForContributorEnabled?: boolean
  codeForTeacherEnabled?: boolean
  codeForStudentEnabled?: boolean
}

// Json Api
interface JsonApiResponse<T, I = any> {
  data?: JsonApiData<T> | Array<JsonApiData<T>>
  errors?: JsonApiError[]
  links?: JsonApiLinks
  meta?: JsonApiMeta
  included?: JsonApiData<I> | Array<JsonApiData<I>>
}

interface JsonApiData<T> {
  id: string
  type: string
  attributes: T
}

interface JsonApiError {
  status: string
  title: string
  detail: string
  code: string
  meta: {}
}

interface JsonApiLinks {
  next: string
}

interface JsonApiMeta {
  page: {
    hasNextPage: boolean
    nextPage: number | null
  }
}

interface WallCreationFromTemplateResult {
  url: string
}

interface WallNameAvailability {
  available: boolean
}

// Wall Access
interface UserWallCollaborator {
  right: WallAccessRight
  role?: WallAccessRole
  type: 'user'
  id: UserId
  avatar: string
  email: string
  name: string
  username: string
  shortName?: string
  isRegistered: boolean
  status: WallCollaboratorStatus
}

interface UserWallCollaboratorInviteByUserId {
  wallId: Id
  role?: WallAccessRole
  email?: never
  userId: Id
}

interface UserWallCollaboratorInviteByEmail {
  wallId: Id
  role?: WallAccessRole
  email: string
  userId?: never
}

type UserWallCollaboratorInvite = UserWallCollaboratorInviteByUserId | UserWallCollaboratorInviteByEmail

interface UserGroupWallCollaborator {
  id: Id
  avatar: string
  name: string
  right?: WallAccessRight
  role?: WallAccessRole
  status?: WallCollaboratorStatus
  type: 'userGroup'
}

type MarkType = 'score'
interface WallGradingContextFile {
  name: string
  url: string
}
interface WallGradingSettings {
  markType?: MarkType
  maxScore?: number
  gradeCalculationMethod?: WallGradeCalculationMethods
  lmsPassbackEnabled?: boolean
  showLmsPassback?: boolean
  lmsLastSynced?: string
  lmsSyncState?: LmsSyncState
  lmsSyncErrorMessage?: string
  aiCustomization?: string
  contextFiles?: WallGradingContextFile[]
}

interface LmsPassbackResponse {
  userSyncState: SyncStateUserIds
  lmsLastSynced: string
  lmsSyncState: LmsSyncState
  lmsSyncErrorMessage: string
}

interface SyncStateUserIds {
  successfulUserIds: Id[]
  failedUserIds: Id[]
  skippedUserIds: Id[]
}

interface WallAccessSettings {
  wallId?: WallId
  privacyPolicyId?: PrivacyPolicyId
  privacyType: WallAccessPrivacyType
  right: WallAccessRight
  role?: WallAccessRole
  publicRole?: WallAccessRole
  libraryRole?: WallAccessRole
  tenantRole?: WallAccessRole
  password: string | null
  collaborators: UserWallCollaborator[]
}

interface WallOnboardingPanel {
  wallId?: WallId
}

interface WallGalleryOnboardingData {
  id: string
  templateName: string
  templateDescription: string
  templatePadletUrl: string
  status: 'Internal' // If there are more potential statuses, you might want to expand this into a union type
  shouldCopyWallpaper: boolean
  postTypes: string // 'none' is the only value here, but if there are more possible values, this should be a union type
  previewPadletUrl: string
  samplePosts: number[][]
  templatePublicKey: string
  category: string[] // Assuming that this is an array of strings
  thumbnailUrl: string
  templateWallId: null // Assuming this is always null based on the provided object
  steps: Array<{
    note: string
    action: string | null // We should make this enum of possible values
  }>
}

interface WallLayout {
  name: string
  value: string
  image_path: {
    desktop: string
    mobile: string
  }
  template_url: string
  long_description: string
}

interface WallLayoutPreviewImage {
  name?: string
  path: string
  originalWidth: string
  originalHeight: string
}

interface WallLayoutAttributes {
  name: string
  value: string
  groupBy: 'section' | null
  icon: {
    path: string
    originalWidth: string
    originalHeight: string
  }
  preview: WallLayoutPreviewImage
  previewMobile: WallLayoutPreviewImage
  thumbnail: {
    path: string
    originalWidth: string
    originalHeight: string
  } | null
}

interface WallSectionBreakoutLink {
  wall_section_id: number
  link: string
}

interface WallSubmissionRequestProps {
  requires_login: boolean
  redirect_location: string
  confirmation_text?: string
}

interface WallSubmissionRequestLink extends WallSubmissionRequestProps {
  link: string
}

interface WallRemakeLinkProps {
  copyPosts: string
  useOriginalPostsAuthorship: boolean
  useOriginalWallpaper: boolean
  addAdminsAsCollaborator: boolean
  limitToOneRemakePerUser: boolean
}

interface WallWhiteboardRemakeLinkProps {
  copyObjects: string
  useOriginalWallpaper: boolean
  addAdminsAsCollaborator: boolean
  limitToOneRemakePerUser: boolean
}

interface WallRemakeLink extends WallRemakeLinkProps {
  link: string
}

interface WallWhiteboardRemakeLink extends WallWhiteboardRemakeLinkProps {
  link: string
}

interface SharedCollection {
  type: 'user' | 'library'
  avatar: string
  name: string
  id: Id
}

// Notifications

interface NotificationChannelInformation {
  notify: boolean
  mandatory: boolean
  available: boolean
}

type NotificationChannelList = Record<NotificationChannelType, NotificationChannelInformation>

interface NotificationSettingApiResponse {
  notification_type: NotificationType
  channels: NotificationChannelList
}

// User follows

interface UserFollowApiResponse {
  followerId: UserId
  user: Partial<User>
}

// Wall follows

interface WallFollowApiResponse {
  userId: UserId
  wall: Partial<Wall>
  follows: boolean
}

interface WallFollowInformation {
  current_user_follows_wall: boolean
  wall_follow_id: Id | null
  wall_follows_count: number
}

// Wall Read Token

interface WallReadTokenResponse {
  token: string
}

// Magic Walls

interface MagicWallCreationResult {
  url: string
}

interface MagicWallCreationStatusResult {
  status: string
  wall_url: string
}

interface MagicTemplate {
  name: string
  key: string
  icon: string
  imageSrc: string
  originalImageWidth: number
  originalImageHeight: number
  isNew?: boolean
  shortName?: string
}

interface BaseMagicTemplateInputConfig {
  label: string
  refKey: string
  optional?: boolean
  toCache?: boolean
  defaultValue?: string
}

interface MagicTemplateTextConfig extends BaseMagicTemplateInputConfig {
  type: 'text'
  placeholder: string
}

interface MagicTemplateNumberRangeConfig extends BaseMagicTemplateInputConfig {
  type: 'number-range'
  low: number
  high: number
}

interface MagicTemplateMultilineTextConfig extends BaseMagicTemplateInputConfig {
  type: 'multiline-text'
  placeholder: string
  hasExampleButton?: boolean
  exampleFunction?: () => string
}

interface MagicTemplateGradeConfig extends BaseMagicTemplateInputConfig {
  type: 'grade'
}

interface MagicTemplateDifficultyConfig extends BaseMagicTemplateInputConfig {
  type: 'difficulty'
}

// Union of all types
type MagicTemplateInputConfig =
  | MagicTemplateTextConfig
  | MagicTemplateMultilineTextConfig
  | MagicTemplateGradeConfig
  | MagicTemplateDifficultyConfig
  | MagicTemplateNumberRangeConfig

interface MagicTemplateViewConfig {
  description: string
  inputConfig: MagicTemplateInputConfig[]
}

// Guided Templates
interface DiscussionQuestion {
  question: string
  summary: string
}

interface QuestionAndResponseDiscussionQuestion extends DiscussionQuestion {
  discussion_style: DiscussionStyle.QuestionAndResponse
}

interface DebateDiscussionQuestion extends DiscussionQuestion {
  discussion_style: DiscussionStyle.Debate
  section_one: string
  section_two: string
  section: string
}

interface QuestionAndResponseResponse extends DiscussionQuestion {
  answer: string
}

interface DebateResponse {
  answer: string
  section: string
}

type GuidedDiscussionQuestion = QuestionAndResponseDiscussionQuestion | DebateDiscussionQuestion
type GuidedDiscussionResponse = QuestionAndResponseResponse | DebateResponse

interface GuidedDiscussionQuestionsResponse {
  questions: GuidedDiscussionQuestion[]
}

interface GuidedDiscussionResponsesResponse {
  responses: GuidedDiscussionResponse[]
}
interface ExitTicketConfigurationResult {
  url: string
}

interface ExitTicketConfigurationStatusResult {
  status: string
  wall_url: string
  data: {
    activity_message: string
  }
}

// AI Talk
interface AiTalkResponse {
  url: string
}

interface Voice {
  languageCode: string
  languageName: string
  name: string
}

interface AiTalkLanguageResponse {
  languageCode: string
}

// Google Drive Uploads

interface BeethovenUploadError {
  status: string
  title: string
  detail: string
  code: string
}
interface GoogleDriveUploadResponse {
  url: string
  errors?: BeethovenUploadError[]
}

// TODO: Replace with Vue-definied one after moving to Vue3.
type VueCssClass = string | CSSProperties | VueCssClass[]

// Feed

interface PostFeedItemData {
  post: Post
  author: User
}

// Mentions

type UserMentionSuggestionAttributes = Required<
  Pick<UserWithHashId, 'id' | 'hashid' | 'display_name' | 'username' | 'avatar'>
> & { has_access?: boolean }
type UserMentionSuggestion = Pick<JsonAPIResource<UserMentionSuggestionAttributes>, 'type' | 'attributes'>
interface Command {
  name: string
  description: string
  icon: string
  level: CommandLevel
  action: Function
  filter?: Function
}

// Dashboard settings

interface UserLibrarySettingsApiResponse {
  defaultLibrary: UserLibrarySetting
  librarySettings: UserLibrarySetting[]
}

// Verification Code
type VerificationCodePurpose = 'change_email_address' | 'signup' | 'login'

interface EmailAddressVerificationResult {
  emailAddress: string
  status: string
  loginAsUserUrl?: string
}

// Folders

interface FolderApiResponse {
  id: Id
  name: string
  wallIds: WallId[]
}

// User groups

interface UserGroupFolderApiResponse {
  id: UserGroupId
  name: string
  wallIds: WallId[]
}

interface ContentModerationCategoryDetails {
  name: ContentModerationCategory
  enabled: boolean
  modifiable: boolean
}
interface ContentSafetySettings {
  contentModerationMode: ContentModerationMode
  moderationCategories: ContentModerationCategoryDetails[]
}

interface ContentModerationCategoryRequest {
  name: ContentModerationCategory
  enabled: boolean
}
interface ContentSafetySettingsUpdateApiRequest {
  contentModerationMode?: ContentModerationMode
  moderationCategories?: ContentModerationCategoryRequest[]
}

// Grading, follows grade.rb
interface Grade {
  gradableType: Gradable
  gradableId: Id
  gradedUserId: UserId
  graderUserId?: UserId
  category?: GradeCategory
  value?: string
}

interface CalculatedWallGrade {
  authorId: UserId
  grades: number[]
  calculatedGrade?: number
}

// Google App Licensing
interface GoogleAppLicensingSettingsResponse {
  enabled: boolean
  orderId?: string
  redeemUri?: string
}

interface GoogleAppLicensingCreateResponse {
  orderId?: string
  redeemUri?: string
}
interface GoogleAppLicensingValidateResponse {
  invalidLicense: boolean
  nextUrl?: string
}

// Library Invites
interface LibraryInvitedUser {
  email: string
  password: string
}

// Activate Bulk Pro
interface InvoiceDetails {
  email: string
  invoiceId: string
  invoiceLink: string
  invoiceType: string
  name: string
  subscriptionId: string
  subscriptionUrl: string
  userId: number
}

interface InvoiceCheckResponse {
  chargebeeSubscriptionId: string
  chargebeeSubscriptionUrl: string
  invoice: Record<string, any>
  invoiceDetails: InvoiceDetails
  licensesLeft: number
  payment: Record<string, any>
  purchasingUser: User
  usersList: User[]
  warningsList: string[]
  paidDomains: string[]
  input_error?: string
}

interface AddLicenseResponse {
  addedCount: number
  usersList: User[]
  successMessage?: string
  warningUsers?: Record<string, string>
  failedUsers?: Record<string, any>
}

interface FailedToCancelLicenseError {
  id: string
  email: string
  error: string
}

interface RemoveLicenseResponse {
  cancelled: UserId[]
  failedToCancel: FailedToCancelLicenseError[]
}

interface DelinquentCardStatusResponse {
  status: DelinquentCardStatus
  dueInvoicesCount: number
}

interface AccountMenuItem {
  tenantId: TenantId
  key: AccountKey
  displayName: string
  logo: string
  fallbackLogo?: string
  tenantDomainName?: string
  isNative: boolean
  isLibrary: boolean
  isSameDomain: boolean
  libraryId: LibraryId | null
  url: string
  wallsUsed: number
  wallsLimit: number
  remainingWallsQuota: number
  canMakeWall: boolean
  canArchive: boolean
  username?: string
  slug?: string
  canViewGallery?: boolean
  membershipTier?: string
}

// SAML

interface SamlConnectionApiResponse {
  id: Id
  allowUpgradeToTeacherRole: boolean
  devMode: boolean
  emailAttribute: string
  idpMetadataUrl: string
  connectionName: string
  nameAttribute: string
  roleAttribute: string
  spEntityId: string
}

// Powwow

interface ArvoConfig {
  cdnHost: string
  jsonApiHost: string
  token: {
    oauthToken: string
  }
}

interface CreateSessionUserApiResponse {
  arvoConfig: ArvoConfig
  user: User
}

interface ModerateUserNameApiResponse {
  userNameHasViolation: null | boolean
}

interface LinkSafetyCheckApiResponse {
  isSafe: string
}

// #region Whiteboard
interface WhiteboardCollaborationSettings {
  collaboration_cursors: ShownCursorType
}

type WhiteboardToolSettings = Record<string, boolean>

interface WhiteboardSoundEffect {
  id: string
  name: string
  category: string
  thumbnail_url: string
  audio_url: string
  published: boolean
  in_review: boolean
  search_keywords: string
  translated_name: string
  translated_category: string
  translated_search_keywords: string
}
// #endregion

type CssClass = string | string[] | Record<string, boolean> | CssClass[]

// #region Wall analytics
interface WallAnalytics {
  visitors: number
  pageviews: number
  engagementTimeMin?: number
}
// #endregion

interface WhiteboardCardBackground {
  id: string
  name: string
  url: string
  thumbnailUrl: string
  category: string
  width: number
  height: number
}

interface WhiteboardCardBackgroundsApiResponse {
  backgrounds: WhiteboardCardBackground[]
}

interface WhiteboardDefaultFrameBackground {
  backgroundType: string
  backgroundUrl: string
}

type WhiteboardDefaultFrameBackgroundApiResponse = WhiteboardDefaultFrameBackground

export type {
  AccountKey,
  AccountMenuItem,
  AccountType,
  // Reactions
  AccumulatedReactionData,
  AccumulatedReactions,
  ActiveInputTag,
  AddLicenseResponse,
  AdvertisedQuotasApiResult,
  AdvertisedQuotasResult,
  AiTalkLanguageResponse,
  AiTalkResponse,
  AttachmentProps,
  BackpackPlanPrices,
  BackpackPlanResult,
  // Google Drive Upload
  BeethovenUploadError,
  Billing,
  BillingFree,
  BillingPaid,
  BillingPauseResult,
  CalculatedWallGrade,
  CanSetWallPrivacyOption,
  CanSetWallPrivacyOptions,
  ChangeCardUrl,
  Cid,
  // Comments
  Command,
  Comment,
  CommentAttachmentSettings,
  CommentId,
  ContentModerationCategoryDetails,
  ContentSafetySettings,
  ContentSafetySettingsUpdateApiRequest,
  CreateSessionUserApiResponse,
  // CssClass
  CssClass,
  DateDisplayFormat,
  DebateDiscussionQuestion,
  DebateResponse,
  DeleteSectionOptions,
  DelinquentCardStatusResponse,
  DeviceDetails,
  DispatchableAction,
  DockableModalState,
  DomainValidation,
  DraftPost,
  EmailAddressVerificationResult,
  EstimateCancellationRefundResult,
  EstimateLibrarySwitchingCostResult,
  EstimateSwitchingCostResult,
  // Libraries
  EstimateTeamNeonDowngradeRefund,
  ExitTicketConfigurationResult,
  ExitTicketConfigurationStatusResult,
  FailedToCancelLicenseError,
  FileAttributes,
  FilterGroup,
  Folder,
  FolderApiResponse,
  FolderId,
  FreezeBannerState,
  GoogleAppLicensingCreateResponse,
  GoogleAppLicensingSettingsResponse,
  GoogleAppLicensingValidateResponse,
  GoogleDriveAuthorizationResult,
  GoogleDriveUploadResponse,
  Grade,
  GuidedDiscussionQuestion,
  GuidedDiscussionQuestionsResponse,
  GuidedDiscussionResponse,
  GuidedDiscussionResponsesResponse,
  HashId,
  Id,
  InvoiceCheckResponse,
  InvoiceDetails,
  JsonApiData,
  JsonApiError,
  // Json Api
  JsonApiResponse,
  LabelledSection,
  Library,
  LibraryAccess,
  LibraryAnalytics,
  LibraryAttributeCheck,
  LibraryBilling,
  LibraryBillingPaid,
  LibraryDowngradeToTeamNeonResult,
  LibraryId,
  LibraryInviteLinks,
  LibraryInvitedUser,
  LibraryInvoice,
  LibraryMembership,
  LibraryMembershipId,
  LibraryOauthSettings,
  LibraryPlan,
  LibrarySwitchResult,
  LibrarySwitchStatusResult,
  LibraryUserEmailDomain,
  LibraryUserQuotaInfo,
  LinkSafetyCheckApiResponse,
  LmsPassbackResponse,
  LocationMenuPlaceInfo,
  // Magic Wall
  MagicTemplate,
  MagicTemplateInputConfig,
  MagicTemplateTextConfig,
  MagicTemplateViewConfig,
  MagicWallCreationResult,
  MagicWallCreationStatusResult,
  MapPostAttributes,
  ModerateUserNameApiResponse,
  // Notifications
  NotificationChannelInformation,
  NotificationChannelList,
  NotificationSettingApiResponse,
  OnboardingData,
  OnboardingTask,
  OnboardingTaskData,
  OnboardingWallViz,
  PinDropSuggestion,
  Plan,
  Point,
  PopoverAnchor,
  Post,
  PostAttributes,
  PostColor,
  PostConnection,
  PostCustomPropertyValue,
  // Feed
  PostFeedItemData,
  PostPositionMap,
  // Wall Access
  PrivacyPolicyId,
  ReactionType,
  // Wall Layout
  RemoveLicenseResponse,
  SamlConnectionApiResponse,
  ScheduledChangesResult,
  SchoolLibraryPermission,
  SchoolLibraryStudentPermission,
  SchoolLibraryTeacherPermission,
  Section,
  SharedCollection,
  SharedCollectionAccountKey,
  SwitchCurrencyResult,
  SwitchPlanResult,
  SyncStateUserIds,
  TemplateFilters,
  Tenant,
  TenantAnalytics,
  TenantId,
  TenantInviteLinks,
  TenantInviteLinksParams,
  TenantParams,
  TenantToLibraryMigrationState,
  Theme,
  UndoLibraryScheduledChangeResult,
  UndoScheduledChangeResult,
  UpdateDraftAttributes,
  UpdatePostArgs,
  UploadedFile,
  User,
  UserCamelCase,
  // User follows
  UserFollowApiResponse,
  UserGroup,
  UserGroupFolderApiResponse,
  UserGroupId,
  // Others
  UserGroupWallCollaborator,
  UserId,
  UserLibrarySetting,
  // Dashboard settings
  UserLibrarySettingsApiResponse,
  UserMentionSuggestion,
  // Mentions
  UserMentionSuggestionAttributes,
  UserOptions,
  UserWallCollaborator,
  UserWallCollaboratorInvite,
  UserWithHashId,
  // Verification Code
  VerificationCodePurpose,
  Voice,
  // Vue
  VueCssClass,
  Wall,
  WallAccessSettings,
  // Wall analytics
  WallAnalytics,
  WallBackground,
  WallCamelCase,
  // Templates
  WallCreationFromTemplateResult,
  WallCustomPostProperty,
  // Wall follows
  WallFollowApiResponse,
  WallFollowInformation,
  WallGalleryOnboardingData,
  WallGalleryTemplate,
  // Wall Grading Settings
  WallGradingContextFile,
  WallGradingSettings,
  WallId,
  WallLayout,
  WallLayoutAttributes,
  WallLayoutPreviewImage,
  WallNameAvailability,
  WallOnboardingPanel,
  WallPostProperties,
  WallReactionData,
  WallReadTokenResponse,
  WallRemakeLink,
  WallRemakeLinkProps,
  WallSectionBreakoutLink,
  WallSubmissionRequestLink,
  WallSubmissionRequestProps,
  WallSuggestedTemplatesData,
  WallTemplate,
  WallViz,
  WallVizNameType,
  WallWhiteboardRemakeLink,
  WallWhiteboardRemakeLinkProps,
  WhiteboardCardBackground,
  WhiteboardCardBackgroundsApiResponse,
  // Whiteboard
  WhiteboardCollaborationSettings,
  WhiteboardDefaultFrameBackground,
  WhiteboardDefaultFrameBackgroundApiResponse,
  WhiteboardSoundEffect,
  WhiteboardToolSettings,
  WishContent,
}
